import { z } from 'zod'
import { phone } from 'phone'
import { accountRoles } from '~/authorization'
import { useArrayAsString } from '~/server/utils'

/* External connection schemas */
export const DEMIS_DISEASE_CODE_ENUM = z.enum(['mpxd', 'cvdd'])
export const SURVNET_DISEASE_CODE_ENUM = z.enum(['mpxd', 'cvdd'])

/* Setup Schemas */
export const supportedLocaleSchema = z.enum(['de', 'en'])
export const sentryVariablesSchema = z.object({
  dsn: z.string().url(),
  environment: z.enum(['development', 'production']),
  sampleRate: z.coerce.number(),
})

/* Common Schemas */
export const idSchema = z.string()
const idArraySchema = idSchema.array().default([])
export const idObjectSchema = z.object({ id: idSchema })

export const whereSchema = z.any().default({})
export const whereObjectSchema = z.object({ where: whereSchema })

export const sortSchema = z.array(z.any()).default([])
export const sortObjectSchema = z.object({ orderBy: sortSchema })

export const querySchema = whereObjectSchema.merge(z.object({
  skip: z.number().nonnegative().optional(),
  take: z.number().nonnegative().optional(),
})).merge(sortObjectSchema).default({})

export const mobilePhoneSchema = z.string().nullish().refine((number) => {
  if (!number) {
    return true
  }
  return phone(number, (number.startsWith('+') ? {} : { country: 'DE' })).isValid
}, { message: 'Invalid phone number' }).transform(number => number ? phone(number, (number.startsWith('+') ? {} : { country: 'DE' })).phoneNumber : undefined)

export const emailSchema = z.string().email()

// parser to transform array to string (to save string array into database eg. files, photos, ...)
export const zodArrayAsString = z.array(z.string()).nullish().transform(val => val ? useArrayAsString(val) : '')

export const addressSchema = z.object({
  street: z.string().min(1),
  postcode: z.string().min(1),
  city: z.string().min(1),
  country: z.string().default('Deutschland'),
  latitude: z.string().nullish(),
  longitude: z.string().nullish(),
})

/* Person */
export const personIsPregnantSchema = z.enum(['no', 'yes', 'unknown', 'none'])
export const personGenderSchema = z.enum(['male', 'female', 'other', 'none'])
export const personIsSmokerSchema = z.enum(['none', 'occasionally', 'regularly', 'never'])
export const personSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  birthDate: z.coerce.date().nullish(),
  address: addressSchema.nullish(),
  reportingAddress: addressSchema.nullish(),
  gender: personGenderSchema.default('none'),
  isPregnant: personIsPregnantSchema.default('none'),
  relevantPredispositions: z.string().array().default([]),
  isSmoker: personIsSmokerSchema.default('none'),
  weightInKg: z.number().nonnegative().nullish(),
  heightInCm: z.number().gte(20).nullish(),
  bmi: z.number().nullish(),
  livingSituation: z.string().nullish(),
  deathDate: z.coerce.date().nullish(),
})
export const updatePersonSchema = z.object({
  id: idSchema,
}).merge(personSchema)
export const createPersonSchema = personSchema.merge(z.object({
  legalRepresentativeId: z.string(),
}))

/* Address Book */
export const createAddressBookEntrySchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  birthDate: z.coerce.date(),
  email: emailSchema,
  createdById: z.string(),
})
/* Institution Type */
export const createInstitutionTypeSchema = z.object({
  name: z.string(),
})
export const updateInstitutionTypeSchema = createInstitutionTypeSchema.merge(idObjectSchema)

/* Institution */
export const institutionMemberRoles = z.enum(['employed', 'resident', 'unknown'])
const institutionMemberSchema = z.object({
  isVisibleByInstitution: z.boolean().default(false),
  role: institutionMemberRoles.default('unknown'),
  person: z.object({
    id: idSchema,
  }),
})
export const institutionMemberShipSchema = institutionMemberSchema.merge(z.object({
  institution: z.object({
    id: z.string(),
  }),
}))
export const institutionPersonRelationSchema = z.object({
  institutionId: z.string(),
  personId: z.string(),
})

export const institutionStatusSchema = z.enum(['active', 'inactive'])
export const institutionSchema = z.object({
  typeId: idSchema.nullish(),
  name: z.string(),
  status: institutionStatusSchema.optional(),
  faxNumber: z.string().nullish(),
  address: addressSchema.nullish(),
  contactPersonFirstName: z.string().nullish(),
  contactPersonLastName: z.string().nullish(),
  membersToAdd: idArraySchema,
  members: institutionMemberSchema.array().optional(),
})
export const createInstitutionSchema = z.object({
  email: emailSchema.nullish(),
  phoneNumber: mobilePhoneSchema.nullish(),
  landLinePhone: z.string().nullish(),
  contactPersonEmail: emailSchema.nullish(),
}).merge(institutionSchema)
export const updateInstitutionSchema = z.object({
  id: idSchema,
  email: emailSchema.nullish(),
  phoneNumber: mobilePhoneSchema.nullish(),
  landLinePhone: z.string().nullish(),
  contactPersonEmail: emailSchema.nullish(),
  account: z.object({
    id: idSchema,
    role: accountRoles,
  }),
}).merge(institutionSchema)

/* Account */
export const accountStatusSchema = z.enum(['new', 'verified', 'needs-check', 'invalid'])
export const createAccountSchema = z.object({
  email: emailSchema.nullish(),
  mobilePhone: mobilePhoneSchema.nullish(),
  landLinePhone: z.string().nullish(),
  role: accountRoles,
  status: accountStatusSchema.optional(),
  person: personSchema.nullish(),
  institution: institutionSchema.nullish(),
})
export const updateAccountSchema = createAccountSchema.merge(idObjectSchema)

export const passwordValidationSchema = z.string().superRefine((password, checkPasswordComplexity) => {
  if (password.length < 8) {
    checkPasswordComplexity.addIssue({ code: 'custom', message: 'length' })
  }
  if (!/[A-Z]/.test(password)) {
    checkPasswordComplexity.addIssue({ code: 'custom', message: 'uppercase' })
  }
  if (!/[a-z]/.test(password)) {
    checkPasswordComplexity.addIssue({ code: 'custom', message: 'lowercase' })
  }
  if (!/[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/.test(password)) {
    checkPasswordComplexity.addIssue({ code: 'custom', message: 'special' })
  }
})

export const linkAccountSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  birthDate: z.coerce.date(),
  email: emailSchema,
})

export const accountMergeQuerySchema = z.object({
  idToMerge: z.string(),
  idToMergeInto: z.string(),
  personIdToMergeInto: z.string().nullish(),
})

/* Disease */
export const diseaseStatusSchema = z.enum(['active', 'inactive'])
export const diseaseTypeSchema = z.enum(['index', 'contact', 'traveller'])
export const diseaseInfectivityBasis = z.enum(['symptom', 'exanthem'])
export const createDiseaseSchema = z.object({
  name: z.string(),
  referenceId: z.string(),
  availableCaseTypes: diseaseTypeSchema.array().default([]),
  status: diseaseStatusSchema,
  comment: z.string().nullish(),
  deactivationDaysOutbreak: z.number().min(0).nullish(),
  deactivationDaysIndex: z.number().min(0).nullish(),
  deactivationDaysContact: z.number().min(0).nullish(),
  deactivationDaysTravel: z.number().min(0).nullish(),
  infectivityBasis: diseaseInfectivityBasis.nullish(),
  infectivityDaysBefore: z.number().min(0).nullish(),
  infectivityDaysAfter: z.number().min(0).nullish(),
  incubationDays: z.number().min(0).nullish(),
  enableVaccinations: z.boolean(),
  vaccines: z.string().array().default([]),
  relevantPredispositions: idArraySchema,
  relevantSymptoms: idArraySchema,
})
export const updateDiseaseSchema = createDiseaseSchema.merge(idObjectSchema)

/* Predisposition */
export const createPredispositionSchema = z.object({
  name: z.string(),
  relevantDiseases: idArraySchema,
})
export const updatePredispositionSchema = createPredispositionSchema.merge(idObjectSchema)

/* Symptoms */
export const createSymptomSchema = z.object({
  name: z.string(),
  snomedCode: z.string().nullish(),
  relevantDiseases: idArraySchema,
})
export const updateSymptomSchema = createSymptomSchema.merge(idObjectSchema)

/* Vaccine */
export const createVaccineSchema = z.object({
  name: z.string(),
  manufacturer: z.string(),
  relevantDiseases: idArraySchema,
})
export const updateVaccineSchema = createVaccineSchema.merge(idObjectSchema)

/* Vaccinations */
export const createVaccinationSchema = z.object({
  vaccineId: z.string(),
  personId: z.string(),
  vaccinatedAt: z.coerce.date(),
})
export const updateVaccinationSchema = createVaccinationSchema.merge(idObjectSchema)

/* Symptom Diary Entries */
export const createSymptomDiaryEntrySchema = z.object({
  comment: z.string(),
  caseId: idSchema,
  symptoms: idArraySchema,
  trackedDate: z.coerce.date(),
  case: z.object({
    patientId: idSchema.nullish(),
  }).nullish(),
})
export const updateSymptomDiaryEntrySchema = createSymptomDiaryEntrySchema.merge(idObjectSchema)

/* Case Teams */
export const createTeamSchema = z.object({
  name: z.string(),
  cases: idArraySchema,
  members: idArraySchema,
})
export const updateTeamSchema = createTeamSchema.merge(idObjectSchema)

/* Case */
export const caseUnconfirmedStates = z.enum(['new', 'inProgress', 'invalid'])
export const caseStateSchema = z.enum(['new', 'inProgress', 'confirmed', 'invalid', 'completed'])
export const caseClosedStates = z.enum(['completed', 'invalid'])
export const caseClosedReasons = z.enum(['recovered', 'moved', 'deceased', 'other'])
export const caseRiskAssessmentSchema = z.enum(['none', 'low', 'medium', 'high'])
const caseStateUpdateBaseSchema = z.object({
  id: idSchema,
  patientId: idSchema.nullish(),
  state: caseStateSchema.exclude(['completed']),
})
export const caseStateUpdateSchema = z.union([
  caseStateUpdateBaseSchema.merge(z.object({
    state: z.literal('completed'),
    closedReason: caseClosedReasons,
    closedComment: z.string().nullish(),
    deathDate: z.coerce.date().nullish(),
  })),
  caseStateUpdateBaseSchema,
])

// Base Schemas
const createCaseBaseSchema = z.object({
  patientId: idSchema,
  diseaseId: idSchema,
  diseaseVariantId: idSchema.nullish(),
  institutionId: idSchema.nullish(),
  teamId: idSchema.nullish(),
  infectionContactDate: z.coerce.date().nullish(),
  infectionStartDate: z.coerce.date().nullish(),
  quarantineStart: z.coerce.date().nullish(),
  quarantineEnd: z.coerce.date().nullish(),
  comment: z.string().nullish(),
  riskAssessment: caseRiskAssessmentSchema.default('none'),
  houseHoldMembersSymptoms: z.string().nullish(),
  addressBookEntryId: idSchema.nullish(),
  infectionAddress: addressSchema.nullish(),
  symptomStartDate: z.coerce.date().nullish(),
  symptomEndDate: z.coerce.date().nullish(),
  exanthemDate: z.coerce.date().nullish(),
  infectivityStartDate: z.coerce.date().nullish(),
  infectivityEndDate: z.coerce.date().nullish(),
  incubationStartDate: z.coerce.date().nullish(),
  incubationEndDate: z.coerce.date().nullish(),
  reportedAt: z.coerce.date().nullish(),
})
export const updateCaseBaseSchema = createCaseBaseSchema.merge(z.object({
  id: idSchema,
  createdAt: z.coerce.date(),
  closedReason: z.string().nullish(),
  closedComment: z.string().nullish(),
  closedAt: z.coerce.date().nullish(),
  closedById: z.string().nullish(),
}))

// Type Schemas
const indexCaseSchema = z.object({
  type: z.literal('index'),
  quarantineStart: z.coerce.date().default(new Date()),
  quarantineEnd: z.coerce.date().default(new Date()),
})
const contactCaseSchema = z.object({
  type: z.literal('contact'),
  indexCaseId: idSchema.nullish(),
})
const travellerCaseSchema = z.object({
  type: z.literal('traveller'),
  travelLocation: z.string().default(''),
  travelReturnDate: z.coerce.date().default(new Date()),
  travelComment: z.string().nullish(),
})

// Combined Schemas
export const createCaseSchema = z.discriminatedUnion('type', [
  indexCaseSchema.merge(createCaseBaseSchema).merge(z.object({ contactCases: idArraySchema })),
  contactCaseSchema.merge(createCaseBaseSchema),
  travellerCaseSchema.merge(createCaseBaseSchema),
])
export const updateCaseSchema = z.discriminatedUnion('type', [
  indexCaseSchema.merge(updateCaseBaseSchema),
  contactCaseSchema.merge(updateCaseBaseSchema),
  travellerCaseSchema.merge(updateCaseBaseSchema),
])

/* Case events */
export const caseEventTypeSchema = z.enum(['phoneCall', 'sms', 'email'])
export const caseEventCreationTypeSchema = z.enum(['plan', 'document'])
export const caseEventPrioritiesSchema = z.enum(['low', 'medium', 'high', 'none'])
export const createCaseEventSchema = z.object({
  type: caseEventTypeSchema,
  assignedTeamId: idSchema.nullish(),
  comment: z.string().nullish(),
  caseId: idSchema.nullish(),
  communicationPriority: caseEventPrioritiesSchema.default('none'),
  personId: idSchema,
  dueAt: z.coerce.date().nullish(),
  completedAt: z.coerce.date().nullish(),
  completedById: idSchema.nullish(),
})
export const updateCaseEventSchema = z.object({
  id: idSchema,
}).merge(createCaseEventSchema)

/* Documents */
export const createDocumentSchema = z.object({
  name: z.string(),
  personId: idSchema.optional(),
  caseId: idSchema.optional(),
  institutionId: idSchema.optional(),
  vaccinationId: idSchema.optional(),
  outbreakId: idSchema.optional(),
  isPostalSendable: z.boolean().default(false),
  isVisibleToCitizen: z.boolean().default(false),
  isVisibleToInstitution: z.boolean().default(false),
  documentTemplateId: z.string().nullish(),
}).refine((data) => {
  const { personId, caseId, institutionId, vaccinationId, outbreakId } = data

  // Refining to make sure specific combinations of IDs are present
  if (personId && !institutionId && !outbreakId) {
    if (caseId && vaccinationId) {
      return false
    }
    // returns true if personId is present with only one or neither of caseId and vaccinationId
    return true
  } else if (institutionId && !personId && !outbreakId && !vaccinationId && !caseId) {
    // returns true if only instututionId is present
    return true
  } else if (outbreakId && !personId && !institutionId && !vaccinationId && !caseId) {
    // returns true if only outbreakId is present
    return true
  }
  return false
}, { message: 'Invalid ID combination' })

/* Message Templates */
export const manualSendMessageSchema = z.object({
  email: emailSchema.nullish(),
  phone: mobilePhoneSchema.nullish(),
  templateId: z.string(),
  recordId: z.string(),
})
export const messageTemplateTypes = z.enum(['email', 'sms'])
export const createMessageTemplateSchema = z.object({
  name: z.string(),
  schema: z.string(),
  type: messageTemplateTypes,
})
export const updateMessageTemplateSchema = createMessageTemplateSchema.merge(z.object({
  id: idSchema,
  text: z.string(),
  title: z.string().nullish(),
  greeting: z.string().nullish(),
  linkText: z.string().nullish(),
}))

/* Document Templates */
export const createDocumentTemplateSchema = z.object({
  name: z.string(),
  schema: z.string(),
})
export const updateDocumentTemplateSchema = createDocumentTemplateSchema.merge(idObjectSchema)

/* Disease Tests */
export const diseaseTestTypeSchema = z.enum(['antibodyIgA', 'antibodyIgG', 'antibodyIgM', 'antibodyUnknown', 'pcrSwab', 'pcrRinse', 'pcrSputum', 'pcrLolli', 'rapid'])
export const diseaseTestResultSchema = z.enum(['positive', 'negative', 'none'])
export const createDiseaseTestSchema = z.object({
  caseId: idSchema,
  reportType: z.string(),
  testType: diseaseTestTypeSchema,
  result: diseaseTestResultSchema,
  testedBy: z.string(),
  testedAt: z.coerce.date(),
  ctValue: z.number().nullish(),
  laboratoryId: idSchema.nullish(),
})
export const updateDiseaseTestSchema = createDiseaseTestSchema.omit({ caseId: true }).merge(idObjectSchema)

/* Outbreaks */
export const outbreakTypeSchema = z.enum(['event', 'cluster', 'screening'])
export const outbreakStateSchema = z.enum(['inProgress', 'confirmed', 'invalid', 'closed'])

export const createOutbreakSchema = z.object({
  title: z.string(),
  diseaseId: idSchema,
  type: outbreakTypeSchema,
  date: z.coerce.date(),
  cases: idArraySchema.optional(),
  address: addressSchema.nullish(),
  institutionId: idSchema.nullish(),
  contactPerson: z.string().nullish(),
  description: z.string().nullish(),
})
export const updateOutbreakSchema = z.object({
  id: idSchema,
  closedComment: z.string().nullish(),
  closedAt: z.coerce.date().nullish(),
  closedById: z.string().nullish(),
}).merge(createOutbreakSchema)

const outbreakStateUpdateBaseSchema = z.object({
  id: idSchema,
  state: outbreakStateSchema.exclude(['closed']),
})
export const outbreakStateUpdateSchema = z.union([
  outbreakStateUpdateBaseSchema.merge(z.object({
    state: z.literal('closed'),
    closedComment: z.string().nullish(),
  })),
  outbreakStateUpdateBaseSchema,
])
/* Address Autocomplete Result */
export const addressAutoCompleteResult = z.object({
  id: z.string(),
  label: z.string(),
  street: z.string(),
  postcode: z.string(),
  city: z.string().default('Köln'),
  state: z.string().nullish(),
  country: z.string().default('Deutschland'),
  longitude: z.string(),
  latitude: z.string(),
})

/* Filter Template */
export const filterTemplateGroupSchema = z.enum(['case', 'diseaseTest', 'outbreak', 'citizen', 'cityEmployee', 'vaccine', 'predisposition', 'symptom', 'institution', 'institutionType', 'institutionReport', 'caseReport', 'caseMap', 'caseEvent'])
export const createFilterTemplateSchema = z.object({
  group: filterTemplateGroupSchema,
  name: z.string(),
  base64: z.string(),
})

/* Immunizations */
export const immunizationTypeSchema = z.enum(['vaccination'])
export const immunizationStateSchema = z.enum(['inProgress', 'completed', 'invalid'])
export const createImmunizationSchema = z.object({
  type: immunizationTypeSchema,
  state: immunizationStateSchema,
  diseaseId: idSchema,
  personId: idSchema,
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullish(),
  validStart: z.coerce.date().nullish(),
  validEnd: z.coerce.date().nullish(),
})
export const updateImmunizationSchema = createImmunizationSchema.merge(idObjectSchema)

/* Disease Variant */
export const createDiseaseVariantSchema = z.object({
  name: z.string(),
  diseaseId: idSchema,
})
export const updateDiseaseVariantSchema = createDiseaseVariantSchema.merge(idObjectSchema)

/* Hospitalizations */
export const createHospitalizationSchema = z.object({
  personId: idSchema.nullish(),
  caseId: idSchema.nullish(),
  institutionId: idSchema.nullish(),
  station: z.string().nullish(),
  startDate: z.coerce.date().default(new Date()),
  endDate: z.coerce.date().nullish(),
  comment: z.string().nullish(),
  hasIntensiveCare: z.boolean().default(false),
})
export const updateHospitalizationSchema = createHospitalizationSchema.merge(idObjectSchema)

/* Notes */
export const createNoteSchema = z.object({
  text: z.string(),
  relatedPersonId: idSchema,
  relatedCaseId: idSchema.nullish(),
})
export const updateNoteSchema = createNoteSchema.merge(idObjectSchema)
